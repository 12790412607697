import React from 'react';
import logo from '../../IMG/logo-panikboard.gif';
import 'CSS/Member/Login.css';
import Form from 'Components/Core/Form/Form';
import FormElement from '../Core/Form/FormElement';
import Dialog from 'Components/Core/Dialog';

export default function Login(props) {

	const divReference = React.useRef(null);
	const [message, setMessage] = React.useState('');
	const [messageClass, setMessageClass] = React.useState('error-message');
	const wafId = props.id;
	const newMemberId = props.id + '-new-member'
	const wad = props.wa.dialog;
	const waf = props.wa.form;
	const wam = props.wa.module;
	const was = props.wa.system;
	const wau = props.wa.utility;
	const waw = props.wa.webSocket;

	const privateMessage = (was.privateMessage !== '') ? <div>{was.privateMessage}</div> : null;
	const loginMessage = <span>Bitte melden Sie sich an.</span>;

	wam.init(props.id);
	waf.init(wafId, {
	});

	React.useEffect(() => {
		waf.mandatory(newMemberId, 'registrationCode', true);
		waf.mandatory(newMemberId, 'newMemberName', true);
		waf.mandatory(newMemberId, 'newName', true);
		waf.mandatory(newMemberId, 'newFirstName', true);
		waf.mandatory(newMemberId, 'newPassword', true);
		waf.mandatory(newMemberId, 'newMail', true);

	}, [waf, newMemberId]);
	React.useEffect(() => {
		wad[props.id].show(() => { waf.focus('login', 'membername'); });
	});

	function onAfterLogin(data) {
		if (data.content['login'] === 1) {
			waw.send('login', { "module": props.moduleId, "member": waf.get(wafId, 'membername'), "memberlevel": data.content['memberlevel'] });
			if (waf.get(wafId, 'autologin') === 1) {
				localStorage.setItem('token', data.content['token']);
			}
			else {
				localStorage.removeItem('token');
			}
			setTimeout(window.location.reload(), 1000);
		}
		else {
			waf[wafId].enableSendButton(waf.checkChanged(wafId));
			setMessageClass('error-message');
			setMessage('Login mit diesen Daten nicht möglich!');
		}
	}

	function showDialogNewMember() {
		wad[newMemberId].show(() => { waf.focus(newMemberId, 'registrationCode') });		
	}

	function onSubmitNewMember(event) {
		if (wau.validPassword(waf.get(newMemberId, 'newPassword'))) {
			
		}
		waf[newMemberId].ok(event);
	}

	function onAfterRegistration(data) {	
		setMessage(data.content.message);
		if (data.returncode === 1) {
			setMessageClass('success-message');
		}
		else {
			setMessageClass('error-message');
		}
	}
	
	const newMemberButton = <button 
								type="button" 
								key="registration" 
								className="btn " 
								onClick={showDialogNewMember}
							>Registrieren</button>

	return (
		<div id="login-frame" ref={divReference} >
			<Dialog id={props.id} wa={props.wa} classes="login" title="Login" closeIcon={false}>

				<div id="login" className={``}>
					<div >
						<div className="logo">
						<img alt="Logo" src={logo} />
						</div>
						<Form wa={props.wa}
							wafId={wafId}
							module="Public"
							sendTask="Login"
							focusElement="membername"
							classes="settings-form"
							cancelButton={false}
							sendButtonTitle="Login"
							enableSendButton={true}
							buttons={[newMemberButton]}
							onAfterSend={onAfterLogin}
						>
							<FormElement wa={props.wa} wafId={props.id} id="membername" title="Pseudonym" fieldValue="" />
							<FormElement wa={props.wa} wafId={props.id} type="password" id="password" title="Password" fieldValue="" />
							<FormElement type="checkbox" wa={props.wa} wafId={props.id} id="autologin" title="Automatisch anmelden" />
							<div className={`form-group ${(message !== '') ? 'd-block' : 'd-none' } ${messageClass}`} >
								<label id="login-error" >{message}</label>
							</div>
							<div className={`form-group ${(message === '') ? 'd-block' : 'd-none'}`} >
								<span className='message'>
									{loginMessage}
								</span>
							</div>
							<span className='message'>
								{privateMessage}
							</span>
						</Form>
					</div>
				</div>
			</Dialog>
			<Dialog id={newMemberId} wa={props.wa} classes="new-member " title="Mitglied werden" >
				<Form wa={props.wa}
					wafId={newMemberId}
					dialogId={newMemberId}
					module="Public"
					sendTask="Registration"
					focusElement="membername"
					classes="settings-form"
					cancelButton={true}
					sendButtonTitle="Registrieren"
					enableSendButton={true}
					onSubmit={onSubmitNewMember}
					onAfterSend={onAfterRegistration}
				>
					<FormElement wa={props.wa} wafId={newMemberId} id="registrationCode" maxLength={36} title="Registrierungs-Code" fieldValue="" />
					<FormElement wa={props.wa} wafId={newMemberId} id="newMemberName" title="Pseudonym" fieldValue="" />
					<FormElement wa={props.wa} wafId={newMemberId} id="newName" title="Name" fieldValue="" />
					<FormElement wa={props.wa} wafId={newMemberId} id="newFirstName" title="Vorname" fieldValue="" />
					<FormElement wa={props.wa} wafId={newMemberId} id="newPassword" title="Das gewünschte Kennwort" fieldValue="" />
					<FormElement wa={props.wa} wafId={newMemberId} id="newMail" title="Mail" fieldValue="" />
						<span className='new-member-message'>
							Eine Registrierung ist nur durch Empfehlung eines Mitglieds möglich. <br/>
							Dieses Mitglied wird ihnen den Registrierungs-Code nennen. <br/>
						</span>
				</Form>

			</Dialog>
		</div>
	);

}
